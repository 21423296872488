<template>
  <div v-if="!tokenChecked">
    <div class="loader-container">
      <div class="row justify-content-center">
        <div class="col-md-4 d-flex justify-content-center">
          <div class="loader">Loading...</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="tokenIsInvalid">
    <div class="container">
      <InvalidToken />
    </div>
  </div>
  <div v-else>
    <CustomerTopNav
      v-bind:storeLogo=logo
      v-bind:showCount=false
      v-bind:maxCount=0
      v-bind:selectedCount=0
      v-bind:requirePayment=false
      v-bind:totalPrice=0
      v-bind:currency=data.currency
    />
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-4 pb-4 animated-container" :style="containerStyles">
      <div class="row">
        <div class="col-12 col-md-6">
          <img :src="data.picture_url" class="w-100">
        </div>
        <div class="col-12 col-md-6 align-self-center">
          <h1 :style="headerStyles">{{ $t('paymentFailed') }}</h1>
          <div>{{ $t('paymentErrorOccuredText') }}</div>
          <div class="mt-3">
            <a :href="data.campaign_url">
              <button type="button" class="btn btn-lg btn-primary btn-block mt-2 w-50" :style="buttonStyles.primary">{{ $t('tryAgain') }}</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import CustomerTopNav from '../components/CustomerTopNav.vue'
import InvalidToken from '../components/InvalidToken.vue'

export default {
  name: 'CustomerCampaignPaymentCanceled',
  components: {
    CustomerTopNav,
    InvalidToken
  },
  data() {
    return {
      tokenChecked: false,
      tokenIsInvalid: false,
      updating: false,
      data: {},
      token: null,
    }
  },
  created: function () {
    this.cancelPayment()
  },
  computed:{
    logo(){
      if(this.data.logo){
        let url = this.data.logo;
        if(url.startsWith(process.env.VUE_APP_IMAGE_HOST)){
          return url;
        } else {
          return process.env.VUE_APP_IMAGE_HOST + url;
        }
      }
      return this.data.store_logo
    },
    headerStyles() {
      let values = {}
      if(this.data.template){
        if(this.data.template.font_color_title){
          values['color'] = this.data.template.font_color_title;
        }
      }
      return values;
    },
    containerStyles() {
      let values = {}
      if(this.data.template){
        if(this.data.template.container_bg_color){
          values['background-color'] = this.data.template.container_bg_color;
        }
        if(this.data.template.font_color_body){
          values['color'] = this.data.template.font_color_body;
        }
      }
      return values;
    },
    buttonStyles(){
      let styles = {
        primary: {},
        secondary: {}
      }

      if(this.data.template){
        // primary button
        if(this.data.template.button_primary_bg_color){
          styles.primary['background-color'] = this.data.template.button_primary_bg_color;
        }
        if(this.data.template.button_primary_border_color){
          styles.primary['border-color'] = this.data.template.button_primary_border_color;
        }
        if(this.data.template.button_primary_font_color){
          styles.primary['color'] = this.data.template.button_primary_font_color;
        }
        // secondary button
        if(this.data.template.button_secondary_bg_color){
          styles.secondary['background-color'] = this.data.template.button_secondary_bg_color;
        }
        if(this.data.template.button_secondary_border_color){
          styles.secondary['border-color'] = this.data.template.button_secondary_border_color;
        }
        if(this.data.template.button_secondary_font_color){
          styles.secondary['color'] = this.data.template.button_secondary_font_color;
        }
      }
      return styles;
    },
  },
  methods: {
    cancelPayment(){
      const vm = this;
      const api_url = process.env.VUE_APP_API_URL + '/api/campaigns/open/'+ this.$route.params.token + '/cancel-payment/';
      let method = 'GET';
      this.$http({
        method: method,
        url: api_url,
      })
      .then(function (response) {
        // set locale
        vm.$i18n.locale = response.data.language;
        vm.data = response.data;
        if(response.data.template){
          vm.$store.dispatch('setCustomerCampaignTemplate', response.data.template);
        }
        vm.tokenChecked = true;
      })
      .catch(function () {
        vm.tokenIsInvalid = true;
        vm.tokenChecked = true;
        vm.updating = false;
      });
    }
  }
}

</script>
